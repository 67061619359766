html {
	position: relative;
	min-height: 100%;

	&.popup-opened {
		overflow: hidden;
	}
}

/* ********************** MODAL WINDOW ********************************/

.my_overlay {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(44, 47, 57, 0.88);
	cursor: pointer;
	display: block;

	@include break(small) {
		background-color: rgba(44, 47, 57, 0.92);
	}
}

.popup {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 5000;
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;

	.popup-wrapper-inner {
		width: 506px;
		box-shadow: 0 0 35px rgba(49, 64, 61, 0.43);
		border-radius: 75px;
		background-color: #57bb57;
		padding: 60px 40px;
		margin: 70px auto 50px;
		position: relative;
		z-index: 2;

		.wrap-form {
			padding: 0;
			box-shadow: none;
			border-radius: 0;
			background-color: transparent;

			.wrap-input {
				margin-bottom: 20px;
			}

			.wrap-fileupload-box {
				padding-top: 20px;
			}

			.input-field {
				color: #fefefe;
				font-family: "Blogger Sans", sans-serif;
				font-size: 18px;
				font-weight: 300;
				line-height: 1.3;
				padding: 0 12px;
				height: 50px;
				border-radius: 24px;
				border: 2px solid #ffffff;
				display: block;
				background: #57bb57;
				outline: none !important;
				text-align: center;
				width: 300px;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				box-sizing: border-box;

				&::placeholder {
					color: #fefefe;
				}

				&:focus {
					background: #62c762;
				}

				&.invalid-field {
					border-color: #f00;
				}
			}

			textarea {
				color: #fefefe;
				font-family: "Blogger Sans", sans-serif;
				font-size: 16px;
				font-weight: 300;
				line-height: 1.4;
				padding: 12px;
				height: 100px;
				border-radius: 24px;
				border: 2px solid #ffffff;
				background: #57bb57;
				width: 300px;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				box-sizing: border-box;

				&::placeholder {
					color: #fefefe;
				}

				&:focus {
					background: #66db66;
				}
				&.invalid-field, &:invalid {
					border-color: #f00;
				}
			}

			.wrap-submit {
				padding-top: 0;

				input[type="submit"] {
					color: #e61d72;
					font-family: "Blogger Sans", sans-serif;
					font-size: 18px;
					line-height: 1.3;
					font-weight: 400;
					text-transform: uppercase;
					letter-spacing: 0.9px;
					height: 50px;
					box-shadow: 0 0 13px rgba(245, 50, 97, 0.09);
					border-radius: 24px;
					background-color: #ffffff;
					padding: 0 42px;
					border: none;

					&:hover {
						background: #e61d72;
						color: #fff;
					}
				}
 			}
		}

		.caption {
			color: #ffffff;
			font-family: "Blogger Sans", sans-serif;
			font-size: 30px;
			font-weight: 500;
			line-height: 1.3;
			text-transform: none;
			letter-spacing: 0;
			margin-bottom: 35px;
			text-align: center;

			@include break(small) {
				font-size: 20px;
			}
		}
	}

	@include break(small) {
		.popup-wrapper-inner {
			margin-top: 40px;
			width: 90% !important;
			padding: 45px 20px;
			border-radius: 25px;

			input[type="text"],
			input[type="tel"],
			input[type="email"],
			input[type="number"],
			input[type="password"],
			input[type="file"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
			}
		}
	}

	.in {
		padding: 0;
		text-align: center;
	}

	/* end of .in*/
	@include break(x-small) {

	}
	button.popup-close {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjY0IiB2aWV3Qm94PSIwIDAgNjQgNjQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2NCA2NCIgZmlsbD0iI2ZmZiI+ICA8Zz4gICAgPHBhdGggZD0iTTI4Ljk0MSwzMS43ODZMMC42MTMsNjAuMTE0Yy0wLjc4NywwLjc4Ny0wLjc4NywyLjA2MiwwLDIuODQ5YzAuMzkzLDAuMzk0LDAuOTA5LDAuNTksMS40MjQsMC41OSAgIGMwLjUxNiwwLDEuMDMxLTAuMTk2LDEuNDI0LTAuNTlsMjguNTQxLTI4LjU0MWwyOC41NDEsMjguNTQxYzAuMzk0LDAuMzk0LDAuOTA5LDAuNTksMS40MjQsMC41OWMwLjUxNSwwLDEuMDMxLTAuMTk2LDEuNDI0LTAuNTkgICBjMC43ODctMC43ODcsMC43ODctMi4wNjIsMC0yLjg0OUwzNS4wNjQsMzEuNzg2TDYzLjQxLDMuNDM4YzAuNzg3LTAuNzg3LDAuNzg3LTIuMDYyLDAtMi44NDljLTAuNzg3LTAuNzg2LTIuMDYyLTAuNzg2LTIuODQ4LDAgICBMMzIuMDAzLDI5LjE1TDMuNDQxLDAuNTljLTAuNzg3LTAuNzg2LTIuMDYxLTAuNzg2LTIuODQ4LDBjLTAuNzg3LDAuNzg3LTAuNzg3LDIuMDYyLDAsMi44NDlMMjguOTQxLDMxLjc4NnoiLz4gIDwvZz48L3N2Zz4=) no-repeat center center;
		text-indent: -9999px;
		background-size: 28px auto;
		border: none;
		width: 30px;
		height: 30px;
		position: absolute;
		top: -15px;
		right: -15px;
		padding: 0;
		z-index: 10;
		opacity: 0.59;
		cursor: pointer;

		@include break(small) {
			width: 20px;
			height: 20px;
			background-size: 15px auto;
		}

		&:hover {
			opacity: 1;
		}
	}
}


#popup-about-us-more-info {
	.in {
		div[id*="variant-"] {
			display: none;

			ul {
				text-align: left;
				margin-bottom: 20px;
			}

			p {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}