
@mixin break($point) {
	@if $point == min1920 {
		@media  only screen and (min-width: 1920px) { @content; }
	}
	@else if $point == large1920 {
		@media  only screen and (max-width: 1920px) { @content; }
	}
	@else if $point == large1650 {
		@media  only screen and (max-width: 1650px) { @content; }
	}
	@else if $point == large1470 {
		@media  only screen and (max-width: 1470px) { @content; }
	}
	@else if $point == large1300 {
		@media  only screen and (max-width: 1300px) { @content; }
	}
	@else if $point == desktop {
		@media  only screen and (max-width: 1199px) { @content; }
	}
	@else if $point == medium {
		@media only screen  and (max-width: 991px) { @content; }
	}
	@else if $point == medium-second {
		@media only screen  and (max-width: 950px) { @content; }
	}
	@else if $point == small {
		@media  only screen and (max-width: 767px)  { @content; }
	}
	@else if $point == ss-small {
		@media  only screen and (max-width: 670px)  { @content; }
	}
	@else if $point == s-small {
		@media  only screen and (max-width: 570px)  { @content; }
	}
	@else if $point == x-small {
		@media  only screen and (max-width: 480px)  { @content; }
	}
	@else if $point == super-small {
		@media  only screen and (max-width: 415px)  { @content; }
	}
	@else if $point == small-330 {
		@media  only screen and (max-width: 330px)  { @content; }
	}

	@if $point == small-height {
		@media only screen and (max-height: 740px) { @content; }
	}
}


/*
 * A mixin targeting a specific device width and larger.
 * These are the available targets: 'xs', 'sm', 'md', 'lg', 'xl'
 *
 * @param {string} $media The target media
 */
@mixin respond-from($media) {
	@if $media == xs {
		@media (min-width: 0px)  { @content; }
	} @else if $media == sm {
		@media (min-width: 768px) { @content; }
	} @else if $media == md {
		@media (min-width: 1200px) { @content; }
	} @else if $media == lg {
		@media (min-width: 1450px) { @content; }
	} @else if $media == xl {
		@media (min-width: 1800px) { @content; }
	}
}

/*
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .col-[size]-[col]-[total-columns] E.g. .col-xs-6-12
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$grid-columns	Amount of columns
 */
@mixin grid-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.col-#{$size}-#{$i}-#{$grid-columns} {
			width: percentage($i / $grid-columns);
			float: left;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}



 // Generating grid
@include grid-generator(xs, 12);

@include respond-from(sm) {
	@include grid-generator(sm, 12);
}

@include respond-from(md) {
	@include grid-generator(md, 12);
}

@include respond-from(lg) {
	@include grid-generator(lg, 12);
}

@include respond-from(xl) {
	@include grid-generator(xl, 12);
}